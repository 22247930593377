import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding:80px 0;
  max-width: 1400px;
  margin: 0 auto;
  
  @media (max-width: 1440px) {
    margin: 0 5%;
  }
  
  @media (max-width: 768px) {
    margin: 0;
    padding: 40px 20px;
  }
`;

export const SectionTitleWrap = styled.div`
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;

  h2 {
    font-size: 2.5rem !important;
    font-family: "Lora Bold";
    
    @media (max-width: 768px) {
      font-size: 2rem !important;
      padding: 0 20px;
      line-height: 1.2;
    }

    @media only screen and (max-width: 560px) {
      font-size: 2rem !important;
    }
  }
`;

export const StepsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap:10px;
  padding: 0 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;
