import React from "react";
import { withTheme } from "styled-components";
import Typography from "../../../components/Typography";
import Step from "./Step";
import { Container, StepsWrap, SectionTitleWrap } from "./styled";
import { stepsData } from "./_steps";

const StepsSection = ({ theme, title }) => {
  return (
    <Container>
      <SectionTitleWrap>
        <Typography
          as="h2"
          fontFamily="Lora Bold"
          fontSize={{ _: "2rem", md: "2.5rem" }}
          textAlign="center"
          color="black"
          margin={[0, 0, 20, 0]}
        >
          {title || "Your custom storage solution is just a few steps away!"}
        </Typography>
      </SectionTitleWrap>
      <StepsWrap>
        {stepsData.map((step, key) => (
          <Step
            key={key}
            iconImg={step.iconImg}
            title={step.title}
            summary={step.summary}
            stepsSection={true}
          />
        ))}
      </StepsWrap>
    </Container>
  );
};

export default withTheme(StepsSection);
